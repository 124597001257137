



























































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import InputWithValidation from "@/components/inputs/InputWithValidation.vue";
import { Database } from "@/types/Database";
import { createDatabase, loadDatabase, updateDatabase } from "@/api/databases";

@Component({
  components: {
    ValidationObserver,
    InputWithValidation
  }
})
export default class DatabaseForm extends Vue {
  @Prop() private id!: number;

  title = "Create database";
  buttonText = "Create";
  loading = false;
  disabledButton = false;

  database: Database = {
    name: "",
    months: 1,
    price: 0,
    status: true,
    description: ""
  };

  mounted() {
    if (typeof this.id !== "undefined") {
      this.title = "Update";
      this.buttonText = "Update";
      this.loadDatabase();
    }
  }

  async loadDatabase() {
    const { data } = await loadDatabase(this.id);
    this.database = data;
    this.title = `Update ${this.database.name}`;
  }

  async onSubmit() {
    this.loading = true;
    this.disabledButton = true;
    let title = "Create";
    let message = "Database has been created";
    if (typeof this.id !== "undefined") {
      title = "Update";
      message = "Database has been updated";
      await updateDatabase(this.id, this.database);
    } else {
      await createDatabase(this.database);
    }
    this.openNotificationWithIcon(title, message);
    this.loading = false;
    this.disabledButton = false;
    this.$router.push({ name: "Databases" });
  }

  openNotificationWithIcon(title: string, message: string) {
    this.$notification.success({
      message: title,
      description: message
    });
  }
}
